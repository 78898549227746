import { useState } from "react";
import { FaGithub, FaTwitter } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [toggleState, setToggleState] = useState(false);
    
  let styles;

  if (toggleState) {
      styles = "flex";
  } else {
      styles = "hidden";
  }

  return (
    <nav className="flex mx-12 justify-between h-16 items-center">
        <div className="flex justify-between w-2/3">
            <Link to="/"><h4 className="md:text-lg hover:underline">Dan Perkins</h4></Link>
            <div id="navbar-default" className={`${styles} flex-col bg-primary-dark absolute top-16 left-0 w-full text-center [&>*]:py-3 md:relative md:justify-between md:[&>*]:py-0 md:top-0 md:w-2/3 md:text-left md:left-0 md:flex md:flex-row`}>
                <a href="https://blog.danperkins.dev" className="hover:text-secondary-light">Blog</a>
                <a href="#tools" className="hover:text-secondary-light">Tools</a>
                <a href="#projects" className="hover:text-secondary-light">Projects</a>
                <Link to="/contact" className="hover:text-secondary-light">Contact</Link>
                <div className={`${styles} flex-row mx-auto w-14 justify-between md:hidden`}>
                    <a href="https://github.com/PerkinsDan" className="hover:text-secondary-light"><FaGithub size={25}/></a>
                    <a href="https://twitter.com/PerkinsDan" className="hover:text-secondary-light"><FaTwitter size={25}/></a>
                </div>
            </div>
        </div>
        <div className="flex-row hidden md:flex w-16 justify-between">
            <a href="https://github.com/PerkinsDan" className="hover:text-secondary-light"><FaGithub size={25} /></a>
            <a href="https://twitter.com/PerkinsDan" className="hover:text-secondary-light"><FaTwitter size={25} /></a>
        </div>
        <button onClick={() => setToggleState(!toggleState)} className="md:hidden">
            <GiHamburgerMenu className="h-5"/> 
        </button>
    </nav>
  )
}

export default Navbar;
