const Landing = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-32">
        <h1 className="text-3xl md:leading-normal md:text-[80px]">Dan Perkins</h1>
        <h3 className="md:text-3xl">Fullstack Developer</h3>
        <a href="#tools" className="p-3 mt-6 text-primary-dark rounded-2xl bg-tertiary-dark hover:text-secondary-light">Find out more</a>
    </div>
  )
}

export default Landing
