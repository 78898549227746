const data = [
    {
        title: "Portsmouth Point",
        description: "The Academic Journal for the Portsmouth Grammar School",
        imageLink: "pp-thumbnail.png",
        imageAlt: "Portsmouth Point preview",
        websiteLink: "https://portsmouthpoint.vercel.app",
    },
    {
        title: "SNET",
        description: "Extended Research Project: A Social Network for Schools",
        imageLink: "snet-thumbnail.png",
        imageAlt: "SNET preview",
        websiteLink: "https://snet.danperkins.dev",
    },
];

const Projects = () => {
    return (
        <div id="projects" className="mx-auto mt-32 text-center lg:w-5/6 w-fit">
            <h3 className="mb-12 text-4xl">Projects</h3>
            <div className="flex flex-wrap items-center justify-center w-full gap-20">
                {data.map(
                    ({
                        title,
                        description,
                        imageLink,
                        imageAlt,
                        websiteLink,
                    }) => (
                        <a href={websiteLink} target="_blank" rel="noreferrer">
                            <div className="relative max-h-64 max-w-screen aspect-video">
                                <img
                                    className="absolute w-full h-full duration-150 hover:opacity-30 hover:brightness-50"
                                    src={imageLink}
                                    alt={imageAlt}
                                />
                                <div className="flex items-center justify-center h-full">
                                    View Site
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="mt-6 mb-3 text-xl">{title}</p>
                                <p
                                    className="w-full break-normal"
                                    id="description"
                                >
                                    {description}
                                </p>
                            </div>
                        </a>
                    )
                )}
            </div>
        </div>
    );
};

export default Projects;
