const Blog = () => {
  return (
    <div className="w-fit mx-auto text-center mt-32">
        <hr className="mb-32" />
        <h3 className="text-2xl mb-8">Read my blog</h3>
        <p>If you are interested in Web Development articles, you can read my blog here.</p>
        <a href="https://blog.danperkins.dev" className="underline text-secondary-light hover:text-tertiary-dark">blog.danperkins.dev</a>
    </div>
  )
}

export default Blog
