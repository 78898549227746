const About = () => {
  return (
    <div className="mx-4 mt-32 lg:mx-auto lg:w-1/2 mb-12">
      <h3 className="text-3xl">About Me</h3>
      <div className="grid grid-cols-1 mt-6 gap-8 md:grid-cols-2">
        <div className="bg-tertiary-dark drop-shadow-lg p-4 rounded">
            <p className="bg-secondary-dark px-2 w-fit rounded mb-4">1</p>
            <p>I started my web development journey creating basic frontend sites with HTML, CSS & JS</p>
        </div>
        <div className="bg-secondary-light p-4 rounded text-primary-dark">
            <p className="bg-tertiary-dark px-2 w-fit rounded mb-4 text-primary-light">2</p>
            <p>I proceeded to learn React in JS before migrating into typescript</p>
        </div>
        <div className="bg-secondary-light p-4 rounded text-primary-dark">
            <p className="bg-tertiary-dark px-2 w-fit rounded mb-4 text-primary-light">3</p>
            <p>I have spent some time creating full stack web apps in Next JS with MongoDB</p>
        </div>
        <div className="bg-tertiary-dark drop-shadow-lg p-4 rounded">
            <p className="bg-secondary-dark px-2 w-fit rounded mb-4">4</p>
            <p>I have recently looked into creating rest APIs in ExpessJS and integrating them with a ReactJS frontend</p>
        </div>
      </div>
    </div>
  )
}

export default About
