import Landing from './Landing'
import Tools from "./Tools";
import About from "./About";
import Projects from "./Projects";
import Blog from "./Blog";

const Home = () => {
  return (
    <>
        <Landing />
        <Tools />
        <About />
        <Projects />
        <Blog />
    </>
  )
}

export default Home
